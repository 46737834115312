/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PageLayout from "../PageLayout";
import pageRoutes from "../../page.routes";
import ProjectManagerNavbar from "../ProjectManagerNavbar";
import {useStores} from "../../models";
import {Navigate, useNavigate} from "react-router-dom";
import {ROLES} from "../../services/constants";
import {observer} from "mobx-react";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import MDButton from "../MDButton";
import {Grid} from "@mui/material";

function DashboardProjectManagerLayout({children, title, header, justifyContent, alignContent, marginHorizontal = 10, backButton = false,  backButtonText = "Back to Homepage", backRoute }) {
  const navigate = useNavigate()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const isLoggedIn = loginStore.isLoggedIn;

  const renderMainContent = (children) => {
    return (
      <MDBox display={'flex'} justifyContent={'center'}>
        <PageLayout background="transparent" >
          <ProjectManagerNavbar routes={pageRoutes} />
          {backButton && <MDBox mt={12} ml={10} mr={10}>
            <MDButton variant={'text'} sx={{padding: 0}} color={'primary'} onClick={() => navigate(backRoute)}><ArrowBackIcon/>  {backButtonText}</MDButton>
          </MDBox>}
          <Grid container mt={backButton ? 0 : 12} ml={marginHorizontal} mr={marginHorizontal} alignContent={alignContent} justifyContent={justifyContent} sx={{flexDirection:"column", width:"auto"}} >
            <Grid item   display={'flex'} flexDirection={{xs: 'column', md: 'row'}} pb={3}>
              <MDBox sx={{display:"flex", width:"100%",}}>
              <MDTypography variant={'h2'}>
                {title}
              </MDTypography>
              <Grid item sx={{maxWidth:"100%"}} ml={'auto'}>
                {header}
              </Grid>
                </MDBox>
            </Grid>
            {children}
          </Grid>
        </PageLayout>
      </MDBox>
  );
  }

  // TODO better
  if (isLoggedIn && (loginStore.group === ROLES.PROJECT_MANAGER.name || loginStore.group === ROLES.SUPPLY_MANAGER.name)) {
    return renderMainContent(children);
  } else {
    return <Navigate to='/logout'/>;
  }
}

export default observer(DashboardProjectManagerLayout);
