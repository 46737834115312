/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useState, useEffect, useRef} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {observer} from "mobx-react";

// Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Utils
import {formatNumberToCurrency, showMessage, useApi} from "services/helpers";

// Styles
import {
  ProductImage,
  ProductBrandText,
  ProductDetailBox,
  ProductPriceText, ProductImageContainer
} from "./styles";
import {ROLES, ROUTES} from "../../../services/constants";
import {
  Container,
  FilterItem,
  Input,
  InputContainer
} from "../../../components/FiltersBar/styles";
import {Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../assets/icons/search.svg";
import Pagination from "../../../components/DataTable/Pagination/Pagination";
import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {MainContainer} from "../../admin/manage-products/styles";
import {useStores} from "../../../models";
import {GeneralModal} from "../../../components/general-modal";
import {Form, FormikProvider, useFormik} from "formik";
import FormikInput from "../../../components/FormikInput";
import * as Yup from "yup";
import pxToRem from "../../../assets/theme/functions/pxToRem";

const validationSchema = {
  manufacturer: Yup.mixed().foreignKey("Manufacturer is required"),
  // request_action: Yup.string().required('Request action is required'),
  message: Yup.string()
}

function ProductsPage({showTotalEntries = true}) {
  const api = useApi()
  const {id: idProject, categoryId = null} = useParams();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [searchParams ] = useSearchParams();
  const order_or_quote_id = searchParams.get('oqn');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Products, setProducts] = useState([]);
  // const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const [requestQuoteOpen, setRequestQuoteOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    manufacturer: null,
    message: ''
  })

  useEffect(() => {
    // if(!order_or_quote_id && loginStore.isSupplyManager){
    //   navigate(ROUTES.USER_ORDERS_AND_QUOTES(idProject))
    // }
    getManufacturers()
  }, [idProject, order_or_quote_id])

  const getManufacturers = () => {
    api.projectManufacturers(idProject, { categoryId }).handle({
        onSuccess: (result) => {
          setManufacturers(result.data)
          if (result.data.length === 1) {
            setInitialValues(value => ({...value, manufacturer: result.data[0]}))
          }
        },
        errorMessage: 'Error getting manufacturers',
    })
  }

  const getProducts = (data) => {
    setLoading(true)
    const orderid = order_or_quote_id ? {order_id: order_or_quote_id} : {}
    api.getProjectProducts(idProject, {...data, category_id: categoryId, ...orderid, page_size: 12}).handle({
        onSuccess: (result) => {
          const {count, results} = result.data
          setProducts(results)
          setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))
        },
        errorMessage: 'Error getting products',
        onFinally: () => setLoading(false)
    })
  }

  useEffect(() => {
    getProducts({search: searchQuery});
  }, [order_or_quote_id])

  const getFilters = () => {
    let filters = {search: searchQuery, page: 1, project_id: idProject}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    if (selectedManufacturer) {
      filters.manufacturer_id = selectedManufacturer.id
    }
    if (selectedCategory) {
      filters.category_id = selectedCategory
    }
    return filters
  }

  useEffect(() => {
    getProducts(getFilters())
  }, [searchQuery, selectedManufacturer, selectedCategory])


  const gotoProductDetail = (id) => {
    const q = order_or_quote_id ? `?oqn=${order_or_quote_id}` : ''
    navigate(ROUTES.USER_PRODUCT_DETAIL(idProject, id) + q)
  }

  const handleRequestQuote = (values, formik) => {
    api.requestQuote(idProject, values).handle({
      onSuccess: (result) => {
        setRequestQuoteOpen(false)
        if (result.response) {
          navigate(ROUTES.USER_ORDERS_AND_QUOTES_DETAIL(idProject, result.response))
        }
      },
      successMessage: 'Quote requested successfully',
      errorMessage: 'Error requesting quote',
      onError: (error) => { formik.setErrors(error?.errors) }
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validationSchema: Yup.object().shape(validationSchema),
    enableReinitialize: true,
    onSubmit: handleRequestQuote,
  })

  return (
    <DashboardProjectManagerLayout
    >
      <MDBox sx={MainContainer}>

        <GeneralModal open={requestQuoteOpen} handleClose={() => setRequestQuoteOpen(false)} title={'Request Quote'} >
            <FormikProvider value={formik}>
                <Form>
                  <Grid container columns={{md: 12}} p={3} pb={0}>
                    <Grid item md={6} p={2}>
                      <FormikInput
                          type={"autocomplete"}
                          value={formik.values.manufacturer}
                          fieldName={"manufacturer"}
                          label={"Sending To"}
                          options={manufacturers}
                          accessKey={"name"}
                          onChange={(value) => {
                            formik.setFieldValue('manufacturer', value)
                          }}
                      />
                    </Grid>
                  </Grid>
                  <FormikInput name={'message'} multiline rows={6} type={'textarea'} label={'Message (Optional)'} errors={formik.errors} pl={5} pr={5}/>
                  <MDBox display={'flex'} justifyContent={'flex-end'} sx={{ borderTop: '1px solid #DBDBDB' }} p={4} gap={2}>
                    <MDButton variant={'outlined'} color={'secondary'} onClick={() => setRequestQuoteOpen(false)}>Cancel</MDButton>
                    <MDButton type={'submit'} color={'secondary'}>Request Quote</MDButton>
                  </MDBox>
                </Form>
          </FormikProvider>
        </GeneralModal>

        <MDBox
          sx={Container}
        >

          {loginStore.isProjectManager &&
            <MDBox sx={FilterItem}>
              <MDButton sx={{ml: 2}}  size={'small'} color={'secondary'} onClick={() => navigate(ROUTES.CONTACT_LIST_PROJECT_MANAGER(idProject))}>View Contacts</MDButton>
              <MDButton variant="outlined" color="secondary" sx={{ml: 2}} onClick={() => setRequestQuoteOpen(true)}>Request Quote</MDButton>
              <MDButton size={'small'} variant="outlined" color="secondary" sx={{ml: 2}} onClick={() => navigate(ROUTES.PM_PROGRAM_DOCUMENTS(idProject, categoryId))}>Program Documents</MDButton>
            </MDBox>
          }


          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}
              InputProps={{
                endAdornment:
                  <InputAdornment position="start" sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for a product'}
            />
          </MDBox>
        </MDBox>
        <Grid container spacing={5}>
          {Products?.length > 0 ? Products.map((product, index) => (
              <Grid item xs={12} md={6} lg={3} key={`product-key-${index}`}
                    onClick={() => gotoProductDetail(product.id)}>
                <MDBox sx={ProductImageContainer}>
                  <MDBox
                    component={'img'}
                    src={product.main_img}
                    sx={ProductImage}
                  />
                </MDBox>

                <MDBox sx={ProductDetailBox}>
                  <MDTypography variant='button' sx={ProductBrandText}>{product.manufacturer}</MDTypography>
                  <MDTypography variant='h3' sx={{width: '100%', wordWrap: 'break-word'}}>{product.name}</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Model {product.model}</MDTypography>
                  <MDTypography variant='h3' sx={ProductPriceText}>
                    {formatNumberToCurrency(product.price)}
                    <MDTypography variant='button' fontWeight='regular'>/unit</MDTypography>
                  </MDTypography>
                </MDBox>
              </Grid>
            )) :
            <MDBox display={'flex'} alignItems={'center'} justifyContent={'center'} minHeight={'50vh'} width={'100%'}>
              <MDTypography variant={'subtitle1'}>No items found</MDTypography>
            </MDBox>

          }
        </Grid>
        {showTotalEntries && <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={12}
              onPageChange={page => {
                // page:page is to ensure that we have the current value in the filters
                getProducts({...getFilters(), page: page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>}
      </MDBox>
    </DashboardProjectManagerLayout>
  );
}

export default observer(ProductsPage);

