import AdminLayout from "../../../components/AdminLayout";
import {observer} from "mobx-react";
import DataTable from "../../../components/DataTable";
import MDButton from "../../../components/MDButton";
import {useState} from "react";
import MDBox from "../../../components/MDBox";
import {showMessage, useApi, useIsMobile} from "../../../services/helpers";
import {dataTableModel, renderTableRow} from "./utils";
import SearchBar from "../../../components/SearchBar";
import {CategoryModalForm} from "./categoryModalForm";
import {PopoverActions, PopoverItem} from "../../../components/PopoverActions";
import {PopoverDeleteText, PopoverEditDelete} from "../../../components/PopoverActions/PopoverEditDelete";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import {wrapApiCall} from "../../../services/helpers_ts";
import {apiMessages, useApiMessages} from "../../../services/api/api-messages";

function ManageCategoriesPage() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [paginationData, setPaginationData] = useState({
    counts: 0,
    itemsPerPage: 0,
    currentPage: 1,
    ordering: "",
  });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');
  const messages = useApiMessages('Category', 'cetegories')
  const isMobile = useIsMobile()
  const getCategories = (search = '', page = 1, ordering = paginationData.ordering, page_size = 10) => {
    setLoading(true)
    api.getCategories({search, page, ordering, page_size}).then((result) => {
      if (result.kind === "ok") {
        const {count, results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem))
        setDatatable(tmp)
        setPaginationData((value) => ({
          ...value,
          counts: count,
          itemsPerPage: results.length,
          currentPage: page,
          ordering: ordering
        }))
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const onDelete = () => {
    setLoading(true)
    wrapApiCall(api.deleteCategory(selectedItem?.id), {
          successMessage: messages.delete.success,
          onSuccess: () => { getCategories(searchQuery); onClose() },
          errorMessage: messages.delete.error,
          onFinally: () => setLoading(false)
        }
    )
  }

  const onClose = () => {
    setSelectedItem(null)
    setOpenConfirmModal(false)
    setOpenAddCategoryModal(false)
    setOpenPopover(false)
  }

  return (
    <AdminLayout
      title={'Manage Categories'}
      header={
        <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
          <SearchBar loading={loading} search={getCategories} setSearchQuery={setSearchQuery}/>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={() => setOpenAddCategoryModal(true)}
            sx={isMobile ? { fontSize:"14px", flexWrap:"nowrap", width:"178px", alignContent:"center", pb:"6px"} :{}}
          >
            Add New Category
          </MDButton>
        </MDBox>
      }
    >
      {openAddCategoryModal && (
        <CategoryModalForm
          item={selectedItem}
          open={openAddCategoryModal}
          onClose={() => onClose()}
          setLoading={setLoading}
          loading={loading}
          onSave={() => {
            onClose()
            getCategories(searchQuery);
          }}/>
      )}

      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getCategories}
        searchQuery={searchQuery}
        onPageChange={page => {
          getCategories(searchQuery, page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />

      <ConfirmDialogModal
        title={'Do you want to delete this Category?'}
        description={`${selectedItem?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => onDelete()}
      />

      <PopoverEditDelete
        open={openPopover}
        onClose={() => onClose()}
        onClickEdit={() => setOpenAddCategoryModal(true)}
        onClickDelete={() => setOpenConfirmModal(true)}
        anchorEl={anchorEl}/>


    </AdminLayout>
  );
}

export default observer(ManageCategoriesPage);

