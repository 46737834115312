import * as Yup from "yup";
import FormikInput from "../../../components/FormikInput";
import {PERMISSION_TYPES} from "../../../services/constants";

export const baseValidationRules = {
  name: Yup.string().required('Name is required'),
  title: Yup.string().nullable().required("This field is required"),
  phone_number: Yup.string().nullable().required('Phone number is required'),
  email: Yup.string().email().nullable().required("This field is required"),
}

export const baseInitialValues = {
  name: "",
  title: "",
  phone_number: "",
  email: "",
  coverage:"",
};

export const supplyManagerValidationRules = {
  ...baseValidationRules,
  // coverage_area: Yup.object().nullable(),
  manufacturer: Yup.object().nullable(),
  permission_type: Yup.object().nullable().required('Permission is required'),
  coverage: Yup.string().required("Area is required"),
}

export const projectManagerValidationRules = {
  ...baseValidationRules,
  company: Yup.object().nullable(),
  region: Yup.object().nullable(),
  permission_type: Yup.object().nullable().required('Permission is required'),
}

export const projectManagerInitialValues = {
  ...baseInitialValues,
  company: null,
  region: null,
  permission_type: PERMISSION_TYPES[0]
}

export const renderUserForm = (idx, field, errors, companies = [], regions = [], manufacturers = [], setFieldValue, selectedCompany = {}, selectedRegion = {}, selectedManufacturer = {}, selectedPermissionType ={}) => {

  if (field.name === 'company') {
    return (
      <FormikInput key={idx} {...field} errors={errors} my={2} options={companies} setFieldValue={setFieldValue} initialValue={selectedCompany}/>
    )
  } else if (field.name  === 'region') {
    return (
      <FormikInput key={idx} {...field} errors={errors} my={2} options={regions} setFieldValue={setFieldValue} initialValue={selectedRegion}/>
    )
  } else if (field.name  === 'manufacturer') {
    return (
      <FormikInput key={idx} {...field} errors={errors} my={2} options={manufacturers} setFieldValue={setFieldValue} initialValue={selectedManufacturer}/>
    )
  } else if (field.name  === 'permission_type') {
    return (
      <FormikInput key={idx} {...field} errors={errors} my={2} options={PERMISSION_TYPES} setFieldValue={setFieldValue} initialValue={selectedPermissionType}/>
    )
  } else {
    return (
      <FormikInput key={idx} {...field} errors={errors} my={2}/>
    )
  }
}
