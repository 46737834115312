import {Modal} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import pxToRem from "assets/theme/functions/pxToRem";

const AddBaseModal =
  ({
     open,
     handleClose,
     children,
     modalContainer,
     title,
     buttonText,
     onSubmit,
     disabled,
     headerContent,
     showActions = true,
     loading = false,
   }) => {
    return (
      <Modal open={open} onClose={loading ? () => {} : () => handleClose()}>
        <MDBox sx={[styles.modal, modalContainer]}>
          <MDBox
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            borderBottom="1px solid #DBDBDB"
            padding={pxToRem(20)}
          >
            <MDTypography sx={styles.title}>{title}</MDTypography>
            {headerContent}
          </MDBox>
          {children}
          {showActions && (
            <MDBox mb={1} sx={{padding: pxToRem(30)}} display={'flex'} flex={1} justifyContent={'space-around'}>
              <MDButton
                variant="outlined"
                color="secondary"
                disabled={loading}
                onClick={handleClose}
                type='button'
                sx={{width: pxToRem(150), height: pxToRem(45)}}
              >
                Cancel
              </MDButton>
              <MDButton
                variant="contained"
                color="secondary"
                loading={loading}
                disabled={loading || disabled}
                onClick={onSubmit}
                sx={{width: pxToRem(150), height: pxToRem(45)}}
              >
                {buttonText}
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </Modal>
    )
  };

export default AddBaseModal;

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 444,
    borderRadius: "10px",
    backgroundColor: '#fff',
    border: 'none',
    boxShadow: 24,
  },
  title: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    fontWeight: 400,
    letterSpacing: pxToRem(0.5),
    width: '100%'
  }
}
