import {observer} from "mobx-react";

// Components
import AdminLayout from "../../../components/AdminLayout";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDTypography from "../../../components/MDTypography";
import {
  Container,
  FilterItem,
  FilterItemModified,
  Input,
  InputContainer
} from "../../../components/FiltersBar/styles";
import {Autocomplete, CircularProgress, Grid, InputAdornment, TextField} from "@mui/material";
import search from "../../../assets/icons/search.svg";
import {useEffect, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import Pagination from "../../../components/DataTable/Pagination/Pagination";
import {OrderSummaryCard} from "./orderSummaryCard";

const Index = () => {
  const api = useApi();
  const [orders, setOrders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const [loading, setLoading] = useState(true)

  const getOrders = (data) => {
    setLoading(true)
    api.getOrders(null, {...data}).then((result) => {
      if (result.kind === 'ok') {
        const {count, results} = result.data
        setOrders(results)
        setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))
      }else{
        showMessage('Error fetching orders')
      }
    }).catch((error) => {
      showMessage('Error fetching orders')
    }).finally(() => {
      setLoading(false)
    })
  }

  const getManufacturers = ({search}) => {
    api.getManufacturers({search}).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result.data.results)
      }
    })
  }

  const getCategories = ({search}) => {
    api.getCategories({search}).then((result) => {
      if (result.kind === 'ok') {
        setCategories(result.data.results)
      }
    }).catch(reason => console.log(reason))
  }

  const getFilters = () => {
    let filters = {search: searchQuery, page: 1}
    setPaginationData(value => ({...value, currentPage: filters.page}))
    if (selectedManufacturer) {
      filters.manufacturer_id = selectedManufacturer
    }
    if (selectedCategory) {
      filters.category_id = selectedCategory
    }
    return filters
  }

  useEffect( () => {
    getCategories({search: ''})
    getManufacturers({search: ''})
    getOrders({search: ''})
  }, [])

  useEffect(() => {
    getOrders(getFilters())
  }, [searchQuery, selectedManufacturer, selectedCategory])



  return (
    <AdminLayout
      title={'Order Management'}
    >
      <MDBox sx={styles.mainContainer}>
        <MDBox sx={Container}>
          <MDBox sx={FilterItemModified}>
            {/*<MDBox component={"img"} src={filter} alt={"filter"} width={pxToRem(16)} height={pxToRem(16)}/>*/}
            {/*<MDTypography sx={FilterText}>Filters</MDTypography>*/}
          </MDBox>
          <MDBox sx={FilterItem}>
            <Autocomplete
              disablePortal
              options={manufacturers}
              getOptionLabel={(option) => option.name}
              sx={{ width: 300, ".MuiOutlinedInput-notchedOutline": { border: 'none' }}}
              renderInput={(params) =>
                <TextField {...params} label="Manufacturer"
                           sx={{
                             '.MuiSvgIcon-root': {
                               color: '#0B7340',
                             },
                             '.MuiInputLabel-root': {
                               color: '#000',
                               paddingTop: '6px',
                               fontWeight: 500,
                             }}}
                />}
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setSelectedManufacturer(newValue?.id)
                } else {
                  setSelectedManufacturer(null)
                }
              }}
            />
          </MDBox>
          <MDBox sx={FilterItem}>
            <Autocomplete
              disablePortal
              options={categories}
              getOptionLabel={(option) => option.name}
              sx={{width: 300, ".MuiOutlinedInput-notchedOutline": { border: 'none' }}}
              renderInput={(params) => <TextField {...params} label="Category"
                                                  sx={{
                                                    '.MuiSvgIcon-root': {
                                                      color: '#0B7340',
                                                    },
                                                    '.MuiInputLabel-root': {
                                                      color: '#000',
                                                      paddingTop: '6px',
                                                      fontWeight: 500
                                                    }}}
              />}
              onChange={(event, newValue) => {
                if (newValue?.id) {
                  setSelectedCategory(newValue?.id)
                } else {
                  setSelectedCategory(null)
                }
              }}
            />
          </MDBox>


          <MDBox sx={InputContainer}>
            <TextField
              sx={Input}total
              InputProps={{
                endAdornment:
                  <InputAdornment position="start">
                    <MDBox component={"img"} src={search} alt={"search"}/>
                  </InputAdornment>,
              }}
              onChange={(evt) => setSearchQuery(evt?.target?.value)}
              placeholder={'Search for an order'}
            />
          </MDBox>
        </MDBox>
        {loading &&
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress color="inherit" size={35} />
          </Grid>
        }
        {orders.length > 0 ? orders.map((order) => {
          return (
            <OrderSummaryCard
              key={order.id}
              id={order.id}
              orderTotal={order.total}
              projectManager={order.project_manager}
              project={order.project}
              status={order.status}
              manufacturer={order.manufacturer}
              region={order.location}
              location={order.location}
              is_order={order.is_order}
            />
            )

          }) : !loading &&
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'500px'}>
            <MDTypography variant={'subtitle1'}>No items found</MDTypography>
          </MDBox>
        }
        <Grid container mt={5}>
          <Grid item>
            <MDBox m={2} sx={{color: '#666666', fontSize: 17, width: 300}}>Showing <span
              style={{color: '#000000'}}>{paginationData.itemsPerPage}</span> from <span
              style={{color: '#000000'}}>{paginationData.counts}</span> data</MDBox>
          </Grid>
          <Grid item ml={'auto'}>
            <Pagination
              currentPage={paginationData.currentPage}
              totalCount={paginationData.counts}
              pageSize={10}
              onPageChange={page => {
                // page:page is to ensure that we have the current value in the filters
                getOrders({...getFilters(), page: page})
                setPaginationData(value => ({...value, currentPage: page}))
              }}
            />
          </Grid>
        </Grid>
      </MDBox>

    </AdminLayout>
  )
}

export default observer(Index);
const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    mr: pxToRem(20),
    gap: pxToRem(20),
    width: "100%",
    borderRadius: pxToRem(16)
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: pxToRem(16),
    width: "100%",
    height: pxToRem(62)
  },
  filter: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRight: "1px solid #DBDBDB",
    width: "100%",
    gap: pxToRem(6)
  },
  filterText: {
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: pxToRem(17),
  },
  searchContainer: {
    padding: pxToRem(10),
    width: "100%"
  },
  search: {
    border: "1px solid #DBDBDB",
    borderRadius: pxToRem(8),
    width: pxToRem(388)
  },
  filterIcon: {
    width: pxToRem(16),
    height: pxToRem(16),
  },
  arrowIcon: {
    width: pxToRem(30),
    height: pxToRem(30),
    color: "#0B7340"
  },
}
