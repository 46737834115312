import {FieldArray, FormikProvider, useFormik} from "formik";
import FormikInput from "../../../components/FormikInput";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import {Fragment, memo} from "react";
import {useApi} from "../../../services/helpers";
import {useDebouncedValidate} from "../../../shared/formik_debounce_validate";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import MDTypography from "../../../components/MDTypography";
import {renderCategoryLogo} from "./utils";
import {wrapApiCall} from "../../../services/helpers_ts";
import {useApiMessages} from "../../../services/api/api-messages";

Yup.addMethod(Yup.array, "unique", function (message) {
  return this.test("unique", message, function (list) {
    const mapper = x => x.name;
    const set = [...new Set(list.map(mapper))];
    const isUnique = list.length === set.length;
    if (isUnique) {
      return true;
    }

    const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
    return this.createError({
      path: `filters[${idx}].name`,
      message: message,
    });
  });
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
  logo: Yup.mixed().nullable(),
})


export const CategoryModalForm = memo(({open, item, onClose, onSave, setLoading, loading}) => {
  const api = useApi()
  const messages = useApiMessages('category', 'cetegories')


  const formik = useFormik({
    initialValues: {
      id: item?.id ? item.id : null,
      name: item?.id ? item.name : "",
      logo: item?.id ? item.logo : null
    },
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => save(values),
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  const save = (values) => {
    setLoading(true)
    const keys = []
    if (values.logo) {
      if (item) {
        if (item.logo !== values.logo) {
          keys.push(['logo'])
        } else {
          delete values['logo']
        }
      } else {
        keys.push(['logo'])
      }
    }
    const filters = JSON.stringify(values.filters)
    if (item?.id) {
      editCategory({...values, filters}, keys)
    } else {
      createCategory({...values, filters}, keys)
    }
  }


  const editCategory = (data) => {
    api.editCategory(data).handle({
          successMessage: messages.update.success,
          onSuccess: onSave,
          errorMessage: messages.update.error,
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        }
    )
  }

  const createCategory = (data) => {
    api.createCategory(data).handle({
          successMessage: messages.create.success,
          onSuccess: onSave,
          errorMessage: messages.create.error,
          onError: (result) => { formik.setErrors(result.errors) },
          onFinally: () => setLoading(false)
        }
    )
  }


  return (
    <AddBaseModal
      open={open}
      handleClose={() => {
        formik.resetForm()
        onClose()
      }}
      title={item?.id ? "Edit Category" : "Add Category"}
      buttonText={"Save"}
      onSubmit={formik.handleSubmit}
      loading={loading}
    >
      <MDBox
        display='flex'
        flexDirection='column'
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
        maxHeight={pxToRem(600)}
        sx={{ overflow: 'auto' }}
      >
        <FormikProvider value={formik}>
          <FormikInput name="name" label="Category Name"/>
          {/*<FieldArray*/}
          {/*  name="filters"*/}
          {/*  render={arrayHelpers => {*/}
          {/*    return (*/}
          {/*      <>*/}
          {/*        {formik.values.filters.map((filter, index) => {*/}
          {/*          return (*/}
          {/*            <Fragment key={index}>*/}
          {/*              <FormikInput key={index} name={`filters[${index}].name`} label="Filter" mb={0} pb={0}/>*/}
          {/*              {formik.values.filters.length > 1 && <MDTypography*/}
          {/*                variant="button"*/}
          {/*                color="error"*/}
          {/*                fontWeight="regular"*/}
          {/*                sx={{cursor: 'pointer', marginTop: -3}}*/}
          {/*                textAlign="right"*/}
          {/*                onClick={() => arrayHelpers.remove(index)}*/}
          {/*              >*/}
          {/*                Delete*/}
          {/*              </MDTypography>}*/}
          {/*            </Fragment>*/}
          {/*          )*/}
          {/*        })}*/}
          {/*        <MDTypography*/}
          {/*          variant="button"*/}
          {/*          color="info"*/}
          {/*          fontWeight="regular"*/}
          {/*          sx={{cursor: 'pointer'}}*/}
          {/*          textAlign="right"*/}
          {/*          onClick={() => arrayHelpers.push({name: ""})}*/}
          {/*        >*/}
          {/*          + Add filter*/}
          {/*        </MDTypography>*/}
          {/*      </>*/}
          {/*    )*/}
          {/*  }}*/}
          {/*/>*/}
          <FormikInput
            fullWidth
            filename={"category_logo"}
            type={"file"}
            name={"logo"}
            label={"Category Logo"}
            onChange={(e) => {
              const fileValue = e.target.files[0]
              formik.setFieldValue("logo", fileValue);
            }}
          />
          {item?.id && item.logo && (
            <MDBox display={"flex"} flexDirection={"row"} gap={"5px"} alignItems={"center"}>
              <MDTypography variant={"h5"} mt={"-4px"} fontWeight={"bold"} fontSize={12}>Current logo:</MDTypography>
              {renderCategoryLogo(item)}
            </MDBox>
          )}
        </FormikProvider>
      </MDBox>
    </AddBaseModal>
  )
})
