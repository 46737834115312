import { observer } from "mobx-react";
import { useParams, useSearchParams } from "react-router-dom";
import ItemsToBuyList from "./ItemsToBuyList";
import DashboardProjectManagerLayout from "../../../../components/DashboardProjectManager";
import MDBox from "../../../../components/MDBox";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { Card, Grid, Icon } from "@mui/material";
import { FixedDrawer } from "../fixed-drawer";
import MDTypography from "../../../../components/MDTypography";
import { MiscellaneousCard } from "../miscellaneous-card";
import { useStores } from "../../../../models";
import { openInNewTab, showMessage, useApi } from "../../../../services/helpers";
import { useEffect, useState } from "react";
import OrderHeaderCard from "./OrderHeaderCard";
import {
  FilecardContainer,
  FilenameText,
} from "../../../admin/manage-products/manage_product/styles";
import MDButton from "../../../../components/MDButton";
import * as React from "react";
import { create } from "apisauce";
import OrderPdf from "./OrderPdf";

const CutSheetsTab = ({ projectId, order }) => {
  const api = useApi();
  const [searchParams] = useSearchParams();
  const version_number = searchParams.get("version");

  const documents = {
    "Signed Contract": order?.signed_contract,
    "Delivery Schedule": order?.delivery_schedule,
    "Manufacturer Contract": order?.manufacturer_contract,
  };
  const has_some_document = Object.values(documents).some((document) => document);

  const downloadAllCutsheets = () => {
    // setLoading(true)
    api
      .downloadOrderCutsheets(
        projectId,
        order?.id,
        version_number ? { version: version_number } : {}
      )
      .handle({
        onSuccess: (result) => {
          result.download_file();
        },
        errorMessage: "Error downloading cut sheet file",
      });
  };

  const downloadFile = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Card sx={styles.cardContainer}>
      <MDBox
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"start"}
      >
        <MDTypography
          sx={{
            ...styles.titles,
            // borderTop:  "1px solid #DBDBDB",
            //pb: pxToRem(22)
          }}
        >
          Cut Sheets
        </MDTypography>
        <MDButton variant="outlined" color="secondary" onClick={downloadAllCutsheets}>
          Download All
        </MDButton>
      </MDBox>
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        // justifyContent={'space-between'}
        alignItems={"start"}
      >
        {order?.cutsheets?.map((cutSheet) => (
          <MDBox sx={FilecardContainer} key={cutSheet.id}>
            <MDBox display="flex" alignItems="center">
              <Icon sx={(th) => ({ ml: pxToRem(11), mr: 2 })} fontSize="medium">
                attachment
              </Icon>
              <MDTypography sx={FilenameText}>
                {cutSheet.cut_sheet_file?.name || cutSheet?.cut_sheet_file_name}
              </MDTypography>
            </MDBox>
            <MDTypography
              sx={{ textDecoration: "underline", mr: pxToRem(10), cursor: "pointer" }}
              variant="button"
              fontWeight="regular"
              color="tertiary"
              onClick={() => downloadFile(cutSheet.cut_sheet_file)}
            >
              Download
            </MDTypography>
          </MDBox>
        ))}
      </MDBox>
      {has_some_document && (
        <MDBox
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"start"}
        >
          <MDTypography
            sx={{
              ...styles.titles,
              // borderTop:  "1px solid #DBDBDB",
              //pb: pxToRem(22)
            }}
          >
            Documents
          </MDTypography>
        </MDBox>
      )}
      <MDBox
        display={"flex"}
        flexDirection={"column"}
        // justifyContent={'space-between'}
        alignItems={"start"}
      >
        {Object.entries(documents).map(([docName, doc], index) =>
          doc ? (
            <MDBox sx={FilecardContainer} key={`docs-${index}`}>
              <MDBox display="flex" alignItems="center">
                <Icon sx={(th) => ({ ml: pxToRem(11), mr: 2 })} fontSize="medium">
                  attachment
                </Icon>
                <MDTypography sx={FilenameText}>{docName}</MDTypography>
              </MDBox>
              <MDTypography
                sx={{ textDecoration: "underline", mr: pxToRem(10), cursor: "pointer" }}
                variant="button"
                fontWeight="regular"
                color="tertiary"
                onClick={() => downloadFile(doc)}
              >
                Download
              </MDTypography>
            </MDBox>
          ) : null
        )}
      </MDBox>
    </Card>
  );
};
export const OrderManagementPage = observer(({}) => {
  const api = useApi();
  const rootStore = useStores();
  const { projectStore, loginStore } = rootStore;
  const { id: projectId, ido: orderId } = useParams();
  const [searchParams] = useSearchParams();
  const version_id = searchParams.get("version");
  const [order, setOrder] = useState(null);
  const type = order?.is_order ? "Order" : "Quote";
  const [tabVisible, setTabVisible] = useState(0);

  const updateOrder = () => {
    api
      .getOrderDetail(projectId, orderId, version_id)
      .then((result) => {
        if (result.kind === "ok") {
          // console.log('order: ', result.data);
          if (loginStore.isViewer) result.data.is_editable = false;
          setOrder(result.data);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        showMessage("Error getting order details");
      });
  };

  useEffect(() => {
    if (!orderId) return;
    updateOrder();
  }, [orderId, projectId, version_id]);

  return (
    <DashboardProjectManagerLayout title={`${type} #${orderId}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <MDBox sx={styles.mainContainer}>
            {order && (
              <>
                <OrderHeaderCard
                  key={order.id}
                  order={order}
                  orderTotal={order?.total}
                  projectManager={[order?.project_manager]}
                  project={projectStore.projectName(projectId)}
                  status={order.status}
                  manufacturer={order.manufacturer?.name}
                  location={projectStore.project(projectId)?.location}
                />
                <ItemsToBuyList
                  order={order}
                  onOrderUpdated={updateOrder}
                  onTabChanged={(tab) => setTabVisible(tab)}
                />

                {tabVisible === 1 && <CutSheetsTab projectId={projectId} order={order} />}
              </>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={4}>
          <FixedDrawer order={order} onOrderUpdated={updateOrder} />
        </Grid>
      </Grid>
    </DashboardProjectManagerLayout>
  );
});

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    mr: pxToRem(20),
    // gap: pxToRem(20),
    width: "100%",
    borderRadius: pxToRem(16),
  },
  cardContainer: {
    p: pxToRem(28),
  },
  titles: {
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    pt: pxToRem(20),
    pb: pxToRem(12),
  },
  cardStyle: {
    marginTop: pxToRem(20),
  },
};
