/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";


// Components
import AdminLayout from "components/AdminLayout";
import MDButton from "components/MDButton";

//Utils
import {showMessage, useApi, useIsMobile} from "../../../services/helpers";
import {useEffect, useState} from "react";
import SearchBar from "../../../components/SearchBar";
import MDBox from "../../../components/MDBox";
import {dataTableModel, renderTableRow} from "./utils";
import DataTable from "../../../components/DataTable";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import {PopoverDeleteText, PopoverEditDelete} from "../../../components/PopoverActions/PopoverEditDelete";
import {ROUTES} from "../../../services/constants";
import {PopoverActions, PopoverItem} from "../../../components/PopoverActions";

//Styles

function ManageBrandsPage() {
  const api = useApi()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [openModalForm, setOpenModalForm] = useState(false)
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDuplicateConfirmModal, setOpenDuplicateConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const isMobile = useIsMobile()

  const getBrands = (search = '', page = 1, ordering = '', page_size = 25) => {
    setLoading(true)
    api.getBrands({search, page, ordering, page_size}).then((result) => {
      if (result.kind === "ok") {
        const {count, results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem))
        setDatatable(tmp)
        setPaginationData({
          counts: count,
          itemsPerPage: results.length,
          currentPage: 1
        })
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const onDelete = () => {
    setLoading(true)
    api.deleteBrand(selectedItem?.id).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Brand deleted successfully', 'success')
        getBrands('')
        onClose()
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
        .catch(err => showMessage())
        .finally(() => setLoading(false))
  }

  const duplicate = () => {
    setLoading(true)
    api.duplicateBrand(selectedItem?.id).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Brand duplicated successfully', 'success')
        getBrands('')
        onClose()
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }


  const onClose = () => {
    setOpenPopover(false)
    setSelectedItem(null)
    setOpenConfirmModal(false)
    setOpenModalForm(false)
    setOpenDuplicateConfirmModal(false)
  }



  useEffect(() => {
    getBrands()
  }, [])

  return (
    <AdminLayout
      title={'Manage Brands'}
      header={
        <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
          <SearchBar loading={loading} search={getBrands} setSearchQuery={setSearchQuery}/>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={() => navigate('new')}
            sx={isMobile ? { fontSize:"14px", flexWrap:"nowrap", width:"170px", alignContent:"center", pb:"6px"}:{}}
          >
            Add New Brand
          </MDButton>
        </MDBox>
      }
    >
      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getBrands}
        searchQuery={searchQuery}
        onPageChange={page => {
          getBrands(searchQuery, page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />

      <ConfirmDialogModal
          title={'Do you want to delete this brand?'}
          description={`${selectedItem?.name}`}
          cancelText={'Cancel'}
          confirmText={'Confirm'}
          open={openConfirmModal}
          handleClose={() => onClose()}
          handleConfirm={() => onDelete()}
      />

      <ConfirmDialogModal
        title={'Do you want to duplicate this brand?'}
        description={`${selectedItem?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openDuplicateConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => duplicate()}
      />

      <PopoverActions open={openPopover} onClose={onClose} anchorEl={anchorEl}>
        <PopoverItem label={"Edit"} onClick={() =>  navigate(ROUTES.ADMIN_UPDATE_BRAND(selectedItem.id))}/>
        <PopoverItem label={"Duplicate brand"} onClick={() => setOpenDuplicateConfirmModal(true)}/>
        <PopoverItem label={"Delete"} onClick={() => setOpenConfirmModal(true)} sx={PopoverDeleteText}/>
      </PopoverActions>

    </AdminLayout>
  );
}

export default observer(ManageBrandsPage);
