import {useState} from "react";
import {observer} from "mobx-react";

import MDButton from "components/MDButton";
import AdminLayout from "components/AdminLayout";
import DataTable from "components/DataTable";

import {useApi, useIsMobile} from "../../../services/helpers";
import ConfirmDialogModal from "../../../components/ConfirmDialogModal";
import {CompanyModalForm} from "./components/CompanyModalForm";
import {PopoverEditDelete} from "../../../components/PopoverActions/PopoverEditDelete";
import MDBox from "../../../components/MDBox";
import SearchBar from "../../../components/SearchBar";
import {dataTableModel, renderTableRow} from "./utils";
import {wrapApiCall} from "../../../services/helpers_ts";
import {useApiMessages} from "../../../services/api/api-messages";



function ManageCompaniesPage() {
  const api = useApi()
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [openModalForm, setOpenModalForm] = useState(false)
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
    const messages = useApiMessages('company', 'companies')
  const isMobile = useIsMobile()

  const getAllCompanies = (searchData = '', page = 1, ordering = '') => {
    setLoading(true)
    wrapApiCall(api.getCompanies({search: searchData, page, ordering}), {
          onSuccess: (result) => {
            const {count, results} = result.data
            const tmp = {...dataTableModel}
            tmp.rows = results.map(item => renderTableRow(item, setAnchorEl, setOpenPopover, setSelectedItem))
            setDatatable(tmp)
            setPaginationData(value => ({...value, counts: count, itemsPerPage: results.length}))

          },
          errorMessage: messages.list.error,
          onFinally: () => setLoading(false)
        }
    )
  }

  const onDelete = () => {
    setLoading(true)
    wrapApiCall(api.deleteCompany(selectedItem?.id), {
          successMessage: messages.delete.success,
          onSuccess: () => { getAllCompanies(''); onClose() },
          errorMessage: messages.delete.error,
          onFinally: () => setLoading(false)
        }
    )
  }

  const onClose = () => {
    setOpenPopover(false)
    setSelectedItem(null)
    setOpenConfirmModal(false)
    setOpenModalForm(false)
  }


  return (
    <AdminLayout
      title={'Manage Companies'}
      header={
        <MDBox display={'flex'} flex={1} flexDirection={'row'} alignItems={'center'}>
          <SearchBar loading={loading} search={getAllCompanies} setSearchQuery={setSearchQuery}/>
          <MDButton
            variant="contained"
            color="secondary"
            type='button'
            onClick={() => {
              setSelectedItem(null)
              setOpenModalForm(true)
            }}
            sx={isMobile ? { fontSize:"14px", flexWrap:"nowrap", width:"178px", alignContent:"center", pb:"6px"}:{}}
          >
            Add New Company
          </MDButton>
        </MDBox>
      }
    >
      {openModalForm && (
        <CompanyModalForm
          item={selectedItem}
          open={openModalForm}
          onClose={() => setOpenModalForm(false)}
          setLoading={setLoading}
          loading={loading}
          onSave={() => {
            onClose()
            getAllCompanies(searchQuery);
          }}/>
      )}

      <DataTable
        loading={loading}
        loadingText={'Loading...'}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getAllCompanies}
        searchQuery={searchQuery}
        onPageChange={page => {
          getAllCompanies(searchQuery, page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />

      <ConfirmDialogModal
        title={'Do you want to delete this company?'}
        description={`${selectedItem?.name}`}
        cancelText={'Cancel'}
        confirmText={'Confirm'}
        open={openConfirmModal}
        handleClose={() => onClose()}
        handleConfirm={() => onDelete()}
      />

      <PopoverEditDelete
        open={openPopover}
        onClose={() => onClose()}
        onClickEdit={() => setOpenModalForm(true)}
        onClickDelete={() => setOpenConfirmModal(true)}
        anchorEl={anchorEl}/>

    </AdminLayout>
  );
}

export default observer(ManageCompaniesPage);

