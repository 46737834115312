import {Box} from "@mui/material";
import cart from "../../../../../assets/icons/cart-green.svg";
import MDTypography from "../../../../../components/MDTypography";
import flecha from "../../../../../assets/icons/flecha.svg";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import {Done} from "@mui/icons-material";
import document from "../../../../../assets/icons/document-green.svg";
import track from "../../../../../assets/icons/delivery-track.svg";
import checked from "../../../../../assets/icons/checked-grey.svg";
import {Form, Formik, useField} from "formik";
import {useStores} from "../../../../../models";
import {openInNewTab, useApi} from "../../../../../services/helpers";

export const ContractSectionDone = ({order, setContracts, contracts}) => {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const InputFile = (props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const {htmlFor, fileType, label, errors, bgColor, border, contractName, disable} = props;
    return (
      <>
        <label htmlFor={htmlFor}>
          <MDBox
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={1}
            bgColor={bgColor}
            border={border}
            borderRadius={'5px'}
            sx={{cursor: disable ? 'pointer' : 'inherit'}}
          >
            <MDTypography variant={'h4'} py={1} m={0} ml={0} sx={{color: "#004222"}}>{contractName}</MDTypography>
          </MDBox>
        </label>
        <MDTypography variant='subtitle2' color={'error'}>
          {errorText ? errorText : ''}
        </MDTypography>
      </>
    )
  }
  const DocumentUpload = ({formik, name, managerType, bgColor, border, contractName, disable, fileName, fileUrl}) => {
    const inputID = `input_${Math.random().toString().substring(2, 15)}`
    return (
      <MDBox
      >
        <input
          type='file'
          accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          name={inputID}
          id={inputID}
          disabled={managerType}
          style={{display: 'none'}}
          onInput={(event) => {
            if (event && event.target.files.length > 0) {
              formik.setFieldValue(name, event.target.files[0]);
              setContracts({...contracts, [name]: event.target.files[0]});
            } else {
              formik.setFieldValue(name, null);
              setContracts({...contracts, [name]: null});
            }
          }}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        {fileUrl === null && <>
          {formik.values[name] ? (
              <>
                <MDBox key={`id-${name}`} display={'flex'} justifyContent={'space-between'}>
                  <MDTypography
                    p={0} mt={1} ml={0}
                    variant={'h4'}
                    color={'tertiary'}
                    sx={{
                      fontWeight: 500,
                      textDecoration: "underline",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {formik.values[name]?.name}
                  </MDTypography>
                  <MDTypography
                    variant={'h4'} color={'tertiary'} p={0} mt={1} mx={0} sx={{cursor: 'pointer'}}
                    onClick={() => {
                      formik.setFieldValue(name, null)
                      setContracts({...contracts, [name]: null})
                    }}>Delete</MDTypography>
                </MDBox>
              </>
            ) :
            <InputFile
              htmlFor={inputID}
              errors={formik.errors}
              name={name}
              bgColor={bgColor}
              border={border}
              contractName={contractName}
              disable={disable}
            />
          }
        </>}
        {fileUrl !== null &&
          <MDBox key={`id-${name}`} display={'flex'} justifyContent={'space-between'}>
            <MDTypography
              p={0} mt={1} ml={0}
              variant={'h4'}
              color={'tertiary'}
              onClick={() => openInNewTab(fileUrl)}
              sx={{
                fontWeight: 500,
                textDecoration: "underline",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer'
              }}
            >
              {fileName}
            </MDTypography>
          </MDBox>
        }

      </MDBox>
    )
  }

  return (
    <>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2}
             border={'2px solid #81D61E'} sx={{height: '58px'}}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={cart} alt={"cart"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Collaboration</MDTypography>
        </MDBox>
        <Done sx={{color: "#81D61E", width: 25, height: 25}}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox border={'2px solid #81D61E'} p={2} borderRadius={'xl'}>
        <MDBox display={'flex'} alignItems={'center'} gap={1} pb={1} borderBottom={'1px solid #DBDBDB'}>
          <Box component={"img"} src={document} alt={"document"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Contract</MDTypography>
        </MDBox>

        {/*<MDBox display={'flex'} justifyContent={'space-between'}>*/}
        {/*  <MDTypography variant={'h4'} p={0} mt={1} ml={0}>Your contract is ready</MDTypography>*/}
        {/*  <MDTypography p={0} mt={1} mx={0} variant={'h4'} color={'tertiary'}*/}
        {/*                sx={{fontWeight: 500, textDecoration: "underline", cursor: 'pointer'}}*/}
        {/*                onClick={() => openInNewTab(order.cutsheets[0].cut_sheet_file)}>Download PDF</MDTypography>*/}
        {/*</MDBox>*/}
        <Formik
          initialValues={{signed_contract: null, delivery_schedule: null, manufacturer_contract: null,}}
          enableReinitialize
          onSubmit={values => {
            setContracts(values)
          }}
        >
          {(formik) => {
            return (
              <Form>
                <DocumentUpload
                  formik={formik}
                  name={'signed_contract'}
                  managerType={loginStore.isSupplyManager}
                  bgColor={loginStore.isProjectManager ? '#E6F7D2' : '#F5F5F5'}
                  border={`2px dashed ${loginStore.isProjectManager ? '#2A8457' : '#2A272E'}`}
                  contractName={loginStore.isProjectManager ? 'Upload your signed contract here' : 'Waiting for manager signed contract'}
                  disable={loginStore.isProjectManager}
                  fileName={'Project manager signed contract'}
                  fileUrl={order?.signed_contract}
                />
                <DocumentUpload
                  formik={formik} name={'delivery_schedule'}
                  managerType={loginStore.isSupplyManager}
                  bgColor={loginStore.isProjectManager ? '#E6F7D2' : '#F5F5F5'}
                  border={`2px dashed ${loginStore.isProjectManager ? '#2A8457' : '#2A272E'}`}
                  contractName={loginStore.isProjectManager ? 'Upload your delivery schedule here' : 'Waiting for manager delivery schedule'}
                  disable={loginStore.isProjectManager}
                  fileName={'Delivery schedule'}
                  fileUrl={order?.delivery_schedule}
                />
                <DocumentUpload
                  formik={formik}
                  name={'manufacturer_contract'}
                  managerType={loginStore.isProjectManager}
                  bgColor={loginStore.isSupplyManager ? '#E6F7D2' : '#F5F5F5'}
                  border={`2px dashed ${loginStore.isSupplyManager ? '#2A8457' : '#2A272E'}`}
                  contractName={loginStore.isProjectManager ? 'Waiting for manufacturer contract' : 'Upload your signed contract here'}
                  disable={!loginStore.isProjectManager}
                  fileName={'Manufacturer signed contract'}
                  fileUrl={order?.manufacturer_contract}
                />
              </Form>
            )
          }}
        </Formik>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2}
             border={'2px solid #DBDBDB'} sx={{height: '58px'}}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={track} alt={"track"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Fulfillment</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2}
             border={'2px solid #DBDBDB'} sx={{height: '58px'}}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={checked} alt={"checked"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Complete</MDTypography>
        </MDBox>
      </MDBox>
    </>
  )
}

