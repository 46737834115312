import MDTypography from "components/MDTypography";
import * as React from "react";
import {useState, useEffect} from "react";
import {money_fmt, showMessage, useApi} from "../../../../services/helpers";
import ApproveOrderModal from "../modals/ApproveOrderModal";
import MDBox from "../../../../components/MDBox";
import {ApproveOrderSection} from "../sections/approve-order-section";
import {StepsCompletedSection} from "../sections/steps-completed-section";
import {ShareQuoteSection} from "../sections/share-quote-section";
import {ContractSectionDone} from "../sections/contract-section-done";
import {
  FreshOrderButtons,
  FreshOrderVendorButtons, LockedOrderButtons,
  QuoteButtons,
} from "../sections/share-quote-section/SharedQuotebuttons";
import {
  ContractSectionButtons,
  FulfillmentSectionButtons
} from "../sections/contract-section-done/ContractSectionButtons";
import {useNavigate, useParams} from "react-router-dom";
import {ORDER_PHASES, PM_TASKS, VENDOR_TASKS} from "../../../../services/constants";
import {observer} from "mobx-react";
import {useStores} from "../../../../models";
import {OrderActivity} from "../orderActivity";
import ConfirmDialogModal from "../../../../components/ConfirmDialogModal";
import {ShareOrderModal} from "../modals/ShareQuoteModal";
import MDButton from "../../../../components/MDButton";
import pxToRem from "../../../../assets/theme/functions/pxToRem";

export const FixedDrawer = observer(({order, onOrderUpdated}) => {
  const api = useApi();
  const rootStore = useStores()
  const navigate = useNavigate();
  const {loginStore} = rootStore
  const {id: projectId, ido: orderId} = useParams()
  const [open, setOpen] = useState(false);
  const [openApproveOrder, setOpenApproveOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const orderStage = order?.order_phase || 'quote'
  const orderTasks = order?.tasks || {}
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [contracts, setContracts] = useState({ signed_contract:  null, delivery_schedule:  null, manufacturer_contract:  null, });
  const type = 'Quote'
  const canRejectOrder = false
  const [projectInfo, setProjectInfo] = useState(null);

  const doTask = (task, errorMessage) => {
    setLoading(true)
    api.doOrderTask(projectId, order.id, task).handle({
          onSuccess: (result) => { onOrderUpdated?.() },
          errorMessage: errorMessage || 'Error doing task',
          onFinally: () => { setLoading(false) }
    })
  }

  const approveContract = () => {
    setLoading(true)
    api.approveContract(projectId, order.id, contracts).handle({
        onSuccess: (result) => { onOrderUpdated?.() },
        errorMessage: 'Error approving contract',
        onFinally: () => { setLoading(false) }
    })
  }

  const shareOrder = (task, values) => {
    if (!projectId || !order || !order?.id)
      return
    const data = {task, message: values.message}
    setLoading(true)
    api.shareOrderTask(projectId, order?.id, data).handle({
      onSuccess: (result) => { onOrderUpdated?.() },
      successMessage: values.message ? 'Message sent' : 'Order shared',
      errorMessage: values.message ? 'Error sending message' : 'Error sharing order',
      onFinally: () => { setLoading(false) }
    })
  }
  useEffect(() => {
    if (projectId){
      api.getProject(projectId).handle({
        onSuccess: (result) => setProjectInfo({
          contact_email: result.data.contact_email,
          contact_phone: result.data.contact_phone,
          delivery_instructions: result.data.delivery_instructions,
        }),
        errorMessage: "Error getting project"
      })
    }
  }, []);
  
  const doVendorTask = (task, errorMessage) => {
    setLoading(true)
    api.doOrderVendorTask(projectId, order.id, task).handle({
        onSuccess: (result) => { onOrderUpdated?.() },
        errorMessage: errorMessage || 'Error doing task',
        onFinally: () => { setLoading(false) }
    })
  }

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  }

  const handleClose = (formik) => {
    setOpen(false)
    setOpenApproveOrder(false)
    formik?.resetForm?.()
  }

  const deleteQuote = () => {
    setConfirmDelete(false)
    api.deleteOrder(projectId, orderId).handle({
      onSuccess: (result) => {
        setConfirmDelete(false)
        navigate(-1)
      },
      errorMessage: 'Error deleting quote',
      onFinally: () => { setLoading(false) }
    })
  }

  const renderButtonsStep = () => {
    if (loginStore.isViewer)
      return <></>

    switch (orderStage) {
      case ORDER_PHASES.QUOTE:
        return <QuoteButtons handleA={handleOpenModal} handleB={() => setConfirmDelete(true)} loading={loading}/>
      case ORDER_PHASES.COLLABORATION:
        if (!orderTasks.approve_order && !orderTasks.vendor_approve_order){
          return <FreshOrderButtons handleA={handleOpenConfirmationModal}  handleB={() => setConfirmDelete(true)} loading={loading}/>
        }else{
            return <LockedOrderButtons
                handleA={() => doTask(PM_TASKS.APPROVE_ORDER)}
                handleB={() => doTask(PM_TASKS.EDIT_ORDER)}
                loading={loading}
                lockedByMe={orderTasks.approve_order}
            />
        }
      case ORDER_PHASES.CONTRACT:
        if (!orderTasks.approve_contract){
          return <ContractSectionButtons handleA={handleOpenConfirmationModal} loading={loading}/>
        }
        break
      default:
        // all other cases do nothing

    }
    return <></>
    // return <AcceptOrderButtons handleOpenModal={handleOpenModal} />
  }

  const renderButtonsVendorStep = () => {
    if (loginStore.isViewer)
      return <></>

    switch (orderStage) {
      case ORDER_PHASES.COLLABORATION:
        if (!orderTasks.vendor_approve_order && !orderTasks.approve_order){
          return <FreshOrderVendorButtons
              handleA={handleOpenConfirmationModal}
              handleB={() => doVendorTask(VENDOR_TASKS.REJECT)}
              loading={loading}
          />
        }else{
            return <LockedOrderButtons
                handleA={() => doVendorTask(VENDOR_TASKS.APPROVE_ORDER)}
                handleB={() => doVendorTask(VENDOR_TASKS.EDIT_ORDER)}
                loading={loading}
                lockedByMe={orderTasks.vendor_approve_order}
            />
        }
        break
      case ORDER_PHASES.CONTRACT:
        if (!orderTasks.vendor_approve_contract){
          return <ContractSectionButtons handleA={handleOpenConfirmationModal}
                                         loading={loading}
          />
        }
        break
        case ORDER_PHASES.FULFILLMENT:
          return <FulfillmentSectionButtons handleA={handleOpenConfirmationModal}
                                            loading={loading}
          />
      default:
    }
    return <></>
  }

  const confirmActionHandler = (values = {}) => {
    if (loginStore.isProjectManager){
      switch (orderStage) {
        case ORDER_PHASES.QUOTE:
          return shareOrder(PM_TASKS.SHARE, values)
        case ORDER_PHASES.COLLABORATION:
          if (!orderTasks.approve_order){
            return doTask(PM_TASKS.APPROVE_ORDER, 'Error approving order')
          }
          break
        case ORDER_PHASES.CONTRACT:
          if (!orderTasks.approve_contract){
            if(contracts.signed_contract && contracts.delivery_schedule){
              approveContract()
              break
            } else {
              showMessage('Please first upload your signed contract and delivery schedule')
              break
            }
          }
          break
        default:
        // all other cases do nothing
      }
    } else {
      switch (orderStage) {
        case ORDER_PHASES.COLLABORATION:
          if (!orderTasks.vendor_approve_order){
            return doVendorTask(VENDOR_TASKS.APPROVE_ORDER , 'Error approving order')
          }
          break
        case ORDER_PHASES.CONTRACT:
          if (!orderTasks.vendor_approve_contract){
            if(contracts.manufacturer_contract){
              approveContract()
              break
            } else {
              showMessage('Please first upload your signed contract')
              break
            }
          }
          break
        case ORDER_PHASES.FULFILLMENT:
          return doVendorTask(VENDOR_TASKS.MARK_FULFILLED)
        default:
        // all other cases do nothing
      }
    }

    return <></>
    // return <AcceptOrderButtons handleOpenModal={handleOpenModal} />
  }

  return (
    <MDBox bgColor={'white'} sx={{minWidth:pxToRem(350)}}>
      <ApproveOrderModal
        open={openApproveOrder}
        handleClose={handleClose}
        handleApproveOrder={() => {confirmActionHandler(); handleClose()}}
        loading={loading}
      />
      <ShareOrderModal
        open={open}
        handleClose={handleClose}
        manufacturer={order?.manufacturer}
        handleShareQuote={(values)=> {confirmActionHandler(values); setOpen(false)}}
        loading={loading}
      />
      <ConfirmDialogModal
        open={openConfirmationModal}
        handleClose={() => setOpenConfirmationModal(false)}
        handleConfirm={() => {confirmActionHandler(); setOpenConfirmationModal(false)}}
        description={'Are you sure you want to confirm this action?'}
        confirmText={'Confirm'}
        cancelText={'Cancel'}
      />
      <ConfirmDialogModal
        open={confirmDelete}
        title={`Delete ${type}`}
        handleClose={() => setConfirmDelete(false)}
        handleConfirm={() => deleteQuote()}
        description={'Are you sure you want to confirm this action?'}
        confirmText={'Confirm'}
        cancelText={'Cancel'}
      />
      <MDBox pt={5} px={3}>
        <MDBox display={'flex'} justifyContent={'space-between'} mb={2}>
          <MDTypography variant={'h2'}>
            {type} Stage
          </MDTypography>
        </MDBox>
        {/*{renderStep()}*/}
        {(orderStage === ORDER_PHASES.QUOTE || orderStage === ORDER_PHASES.COLLABORATION) && <ShareQuoteSection type={type} current_status={orderStage} tasks={orderTasks} />}
        {/*{Step === 'approve_contract' && <AcceptOrderSection/>}*/}
        {orderStage === ORDER_PHASES.CONTRACT && <ContractSectionDone order={order} setContracts={setContracts} contracts={contracts}/>}
        {orderStage === ORDER_PHASES.FULFILLMENT && <ApproveOrderSection/>}
        {orderStage === ORDER_PHASES.COMPLETE && <StepsCompletedSection/>}

         <OrderActivity order={order} projectId={projectId} onOrderUpdated={onOrderUpdated} />

        {canRejectOrder && <MDBox display={'flex'} justifyContent={'space-between'} mt={3}>
          <MDButton
              variant={'outlined'}
              color={'secondary'}
              mb={2}
              sx={{ backgroundColor: '#fff', '&:hover': {backgroundColor: '#fff'}, width: '100%' }}
          >
            Reject Order
          </MDButton>
        </MDBox>}

      </MDBox>
      <MDBox pt={2.5} pl={2.8} pb={3.8} pr={2.8} bgColor={'#0B7340'} mt={2}>
        <MDBox display={'flex'} justifyContent={'space-between'}>
          <MDTypography variant={'h6'} color={'white'}>Price</MDTypography>
          <MDTypography variant={'h6'} color={'white'}>{money_fmt(order?.total - order?.taxes)}</MDTypography>
        </MDBox>
        <MDBox display={'flex'} justifyContent={'space-between'}>
          <MDTypography variant={'h6'} color={'white'}>Taxes</MDTypography>
          <MDTypography  variant={'h6'} color={'white'} >{money_fmt(order?.taxes)}</MDTypography>
        </MDBox>
        <MDBox display={'flex'} justifyContent={'space-between'}>
          <MDTypography variant={'h6'} color={'white'}>Grand Total</MDTypography>
          <MDTypography variant={'h2'} color={'white'} >{money_fmt(order?.total)}</MDTypography>
        </MDBox>
        {loginStore.isSupplyManager  && renderButtonsVendorStep()}
        {loginStore.isProjectManager && renderButtonsStep()}
      </MDBox>
      { (projectInfo?.contact_phone || projectInfo?.contact_email || projectInfo?.delivery_instructions) &&
        <MDBox pt={2.5} pl={2.8} pb={3.8} pr={2.8} mt={2}>
          <MDBox display={'flex'} justifyContent={'space-between'} mb={2}>
            <MDTypography variant={'h2'}>
              Delivery instructions
            </MDTypography>
          </MDBox>
          { projectInfo?.contact_phone &&
            <MDBox display={'flex'}>
              <MDTypography variant={'h6'} sx={{mr: "15px"}}>Contact Phone:</MDTypography>
              <MDTypography variant={'h6'} fontWeight='regular'>{projectInfo.contact_phone}</MDTypography>
            </MDBox>
          }
          { projectInfo?.contact_email &&
            <MDBox display={'flex'}>
              <MDTypography variant={'h6'} sx={{mr: "15px"}}>Contact Email:</MDTypography>
              <MDTypography variant={'h6'} fontWeight='regular'>{projectInfo.contact_email}</MDTypography>
            </MDBox>
          }
          { projectInfo?.delivery_instructions &&
            <MDBox display={'flex'}>
              <MDTypography variant={'h6'} sx={{mr: "15px"}}>Delivery Instructions:</MDTypography>
              <MDTypography variant={'h6'} fontWeight='regular'>{projectInfo.delivery_instructions}</MDTypography>
            </MDBox>
          }
        </MDBox>
      }
    </MDBox>
  )
})
