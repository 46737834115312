import {Box} from "@mui/material";
import cart from "../../../../../assets/icons/cart-green.svg";
import MDTypography from "../../../../../components/MDTypography";
import flecha from "../../../../../assets/icons/flecha.svg";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import {Done} from "@mui/icons-material";
import document from "../../../../../assets/icons/document-green.svg";
import truck from "../../../../../assets/icons/truck-green.svg";
import checked_green from "../../../../../assets/icons/checked-green-background.svg";

export const StepsCompletedSection = () => {
  return (
    <>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #81D61E'} sx={{ height: '58px' }}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={cart} alt={"cart"} sx={{ width: 24, height: 24 }}/>
          <MDTypography variant={'h6'}>Collaboration</MDTypography>
        </MDBox>
        <Done sx={{ color: "#81D61E", width: 25, height: 25 }} />
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #81D61E'} sx={{ height: '58px' }}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={document} alt={"document"} sx={{ width: 24, height: 24 }}/>
          <MDTypography variant={'h6'}>Contract</MDTypography>
        </MDBox>
        <Done sx={{ color: "#81D61E", width: 25, height: 25 }} />
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #81D61E'} sx={{ height: '58px' }}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={truck} alt={"truck"}/>
          <MDTypography variant={'h6'}>Fulfillment</MDTypography>
        </MDBox>
        <Done sx={{ color: "#81D61E", width: 25, height: 25 }} />
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{ width: 24, height: 24 }}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2} border={'2px solid #81D61E'} sx={{ height: '58px' }}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={checked_green} alt={"checked_green"}/>
          <MDTypography variant={'h6'}>Complete</MDTypography>
        </MDBox>
        <Done sx={{ color: "#81D61E", width: 25, height: 25 }} />
      </MDBox>
    </>
  )
}
