/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {observer} from "mobx-react";

// MUI Components
import {Accordion, AccordionDetails, AccordionSummary, Autocomplete, Grid, Icon, TextField} from '@mui/material';


// Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardProjectManagerLayout from "components/DashboardProjectManager";

// Utils
import {convertStringToList, money_fmt, openInNewTab, showMessage, useApi} from "services/helpers";

// Styles
import {
  AccordionBox,
  AccordionDivider,
  Divider,
  GeneralInfoContainer,
  GeneralInfoItem,
  GeneralInfoItemHeader,
  GeneralInfoText,
  InputQuantity,
  OtherImage,
  PrimaryImage,
  PrimaryImageContainer,
} from "./styles";
import {MainContainer, ProductBrandText} from "pages/admin/manage-products/styles";
import {FilecardContainer, FilenameText} from "pages/admin/manage-products/manage_product/styles";

// Images and Theme functions
import ExpandMore from 'assets/images/expand_more.svg';
import pxToRem from "assets/theme/functions/pxToRem";
import {ROUTES} from "services/constants";
import CartSectionModal from "../../../../components/CartSectionModal";
import SendIcon from "@mui/icons-material/Send";
import {useStores} from "../../../../models";

export const PMPackageDetailPage = observer(() => {
  const api = useApi()
  const navigate = useNavigate();
  const [searchParams ] = useSearchParams();
  const order_or_quote_id = searchParams.get('oqn');
  const rootStore = useStores()
    const {loginStore} = rootStore
  const [openCartModal, setOpenCartModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {id: idProject, idp: packageId} = useParams();
  const [projectPackage, setProjectPackage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [message, setMessage] = useState('');


  const getPackage = () => {
    api.getProjectPackage(idProject, packageId).then(result => {
      setLoading(true)
      if (result.kind === 'ok') {
        const { data } = result
        const { categories: packageCategories = [], manufacturers: packageManufacturers = [] } = data
        setProjectPackage(data)
        setCategories(packageCategories)
        setManufacturers(packageManufacturers)
      } else {
        showMessage(result.kind === 'not-found' ? 'Package requested not found' : 'Error fetching package')
        navigate(ROUTES.USER_PROJECT_SELECTED(idProject))
      }
    }).catch(error => {
      showMessage()
      navigate(ROUTES.USER_PROJECT_SELECTED(idProject))
    }).finally(() => setLoading(false))
  }

  const sendMessageToManufacturer = () => {
    setLoading(true)
    api.sendEmailToManufacturer(idProject, {message, manufacturer: selectedManufacturer}).handle({
      onSuccess: () => {
        setMessage('');
        setSelectedManufacturer(null)
      },
      successMessage: 'Message sent successfully',
      errorMessage: 'Error sending message',
      onFinally: () => setLoading(false)
    })
  }

  const handleProductOrderQuantityChange = (e) => {
    setQuantity(e.target.value)
  }

  useEffect(() => {
    getPackage();
  }, [])

  const renderProductDetail = (packageProduct) => {
    const product = packageProduct.product
    const productImage = product?.images[0]?.image
    return (
      <Fragment key={`package-product-${packageProduct.id}}`}>
        <Grid container item xs={12} px={3}>
          <Grid item xs={2}>
            <MDBox component='img' src={productImage} sx={OtherImage}/>
          </Grid>
          <Grid item xs={10} pl={2}>
            <MDBox>
              <MDTypography variant='button' sx={ProductBrandText}>{product?.manufacturer?.name}</MDTypography>
              <MDTypography variant='h3'>{product.name}</MDTypography>
              <MDTypography variant='button' fontWeight='regular'>Model {product.model}</MDTypography>
              <MDTypography variant='h3'>
                {money_fmt(packageProduct.part_price)}
                <MDTypography variant='button' fontWeight='regular'>/unit</MDTypography>
              </MDTypography>
            </MDBox>
            <Accordion sx={{width: '100%', boxShadow: 'none'}}>
              <AccordionSummary
                expandIcon={<img src={ExpandMore} alt='expand_more'/>}
              >
                <MDTypography variant='button' sx={ProductBrandText}>View Product Detail</MDTypography>
              </AccordionSummary>
              <AccordionDetails
                sx={{backgroundColor: '#F5F5F5', borderRadius: '16px', boxShadow: 2, mb: 3}}>
                <MDBox mt={pxToRem(24)}>
                  <Accordion sx={AccordionBox}>
                    <AccordionSummary
                      expandIcon={<img src={ExpandMore} alt='expand_more'
                                       style={{marginRight: pxToRem(10)}}/>}
                      sx={{minHeight: 'auto !important'}}
                    >
                      <MDTypography variant='h2'>Product Details</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <MDTypography
                        variant='button'
                        sx={GeneralInfoText}
                        dangerouslySetInnerHTML={{__html: convertStringToList(product?.details)}}
                      ></MDTypography>
                      <MDBox>
                        <MDTypography variant='button' fontWeight='bold' sx={{textDecoration: 'underline'}}>
                          Features
                        </MDTypography>
                        <ul>
                          <MDTypography
                            variant='button'
                            fontWeight='regular'
                            dangerouslySetInnerHTML={{__html: convertStringToList(product?.features)}}
                          />
                        </ul>
                      </MDBox>
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                {/*Accordion Product Details*/}

                {/*Accordion Cut Sheet*/}
                <MDBox sx={AccordionDivider}/>
                <MDBox mt={pxToRem(18)}>
                  <Accordion sx={AccordionBox}>
                    <AccordionSummary
                      expandIcon={<img src={ExpandMore} alt='expand_more'
                                       style={{marginRight: pxToRem(10)}}/>}
                      sx={{minHeight: 'auto !important'}}
                    >
                      <MDTypography variant='h2'>Cut Sheet</MDTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {product?.cut_sheets?.map((cutSheet) => (
                          <MDBox sx={FilecardContainer} key={cutSheet.id}>
                            <MDBox display='flex' alignItems='center'>
                              <Icon sx={(th) => ({ml: pxToRem(11), mr: 2})} fontSize='medium'>attachment</Icon>
                              <MDTypography sx={FilenameText}>
                                {cutSheet.cut_sheet_file?.name || cutSheet?.cut_sheet_file_name.slice(0,23)}
                              </MDTypography>
                              <MDTypography
                                sx={{textDecoration: 'underline', cursor: 'pointer'}}
                                variant='button'
                                fontWeight='regular'
                                color='tertiary'
                                onClick={() => openInNewTab(cutSheet.cut_sheet_file)}>
                                Download
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </MDBox>
                {/*Accordion Cut Sheet*/}

                {/*Accordion Specifications*/}
                <MDBox sx={AccordionDivider}/>
                <MDBox mt={pxToRem(18)}>
                  <Accordion sx={AccordionBox}>
                    <AccordionSummary
                      expandIcon={<img src={ExpandMore} alt='expand_more'
                                       style={{marginRight: pxToRem(10)}}/>}
                    >
                      <MDTypography variant='h2'>Specifications</MDTypography>
                    </AccordionSummary>
                    <ul>
                      <MDTypography variant='button' fontWeight='regular' dangerouslySetInnerHTML={{ __html: convertStringToList(product?.specs) }}/>
                    </ul>
                  </Accordion>
                </MDBox>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <MDBox sx={Divider} pb={2}/>
      </Fragment>
    )

  }

  const renderPackageCategoryProduct = (packageCategory) => {
    return (
      <Grid item xs={12} pt={2} key={'cat-det-id-' + packageCategory.id}>
        <Accordion>
          <AccordionSummary expandIcon={<img src={ExpandMore} alt='expand_more'/>}>
            <MDTypography variant={'h5'} borderBottom={'1px solid #DBDBDB'} width={'100%'} pb={1}>{packageCategory?.category?.name}</MDTypography>
          </AccordionSummary>
          {packageCategory?.products?.map(product => renderProductDetail(product))}
        </Accordion>
      </Grid>
    )
  }


  const renderOrderCategoryProductDetails = (product) => {
    return (
      <Fragment key={'PROD-CAT-' + product.id}>
        <MDTypography variant={'textBold'} width={'100%'} pb={1}>{product?.product?.name}</MDTypography>
        <MDBox sx={GeneralInfoItem}>
          <MDTypography variant='button' fontWeight='regular'>Parts</MDTypography>
          <MDTypography variant='button'
                        fontWeight='regular'>{money_fmt(product.part_price)}</MDTypography>
          <MDTypography variant='button'
                        fontWeight='regular' sx={{ml: pxToRem(10)}}>{product.quantity}</MDTypography>
          <MDTypography variant='button'
                        fontWeight='bold'>{money_fmt(product.quantity * product.part_price)}</MDTypography>
        </MDBox>
        <MDBox sx={GeneralInfoItem}>
          <MDTypography variant='button' fontWeight='regular'>Shipping</MDTypography>
          {product.include_shipping?
            <MDTypography variant='button' fontWeight='regular'>Shipping Included</MDTypography>:
            <MDTypography variant='button'
                          fontWeight='regular'>{money_fmt(product.shipping_price)}</MDTypography>
          }
          <MDTypography variant='button' fontWeight='regular'
                        sx={{ml: pxToRem(10)}}>{!product.include_shipping && product.quantity}</MDTypography>
          <MDTypography variant='button'
                        fontWeight='bold'>{money_fmt(product.quantity * product.shipping_price)}</MDTypography>
        </MDBox>
        {product.include_installation === true &&
          <MDBox sx={GeneralInfoItem}>
            <MDTypography variant='button' fontWeight='regular'>Installation</MDTypography>
            <MDTypography variant='button'
                          fontWeight='regular'>{money_fmt(product.installation_price)}</MDTypography>
            <MDTypography variant='button' fontWeight='regular'
                          sx={{ml: pxToRem(10)}}>{product.quantity}</MDTypography>
            <MDTypography variant='button'
                          fontWeight='bold'>{money_fmt(product.quantity * product.installation_price)}</MDTypography>
          </MDBox>
        }
      </Fragment>
    )

  }

  const renderOrderCategoryDetails = (category) => {
    return (
      <Fragment key={'cat-id-' + category.id}>
        <MDTypography variant={'h6'} width={'100%'} pb={1}>{category?.category?.name}</MDTypography>
        {category?.products?.map(product => renderOrderCategoryProductDetails(product))}
      </Fragment>
    )
  }

  return (
    <DashboardProjectManagerLayout
    >
      <MDBox sx={MainContainer}>
        <MDBox onClick={() => navigate(-1)} display='flex' alignItems='center' gap={pxToRem(5)} mb={pxToRem(20)}
               sx={{cursor: 'pointer'}}>
          <Icon sx={({palette: {icons}}) => ({color: icons.arrow_icon, fontSize: pxToRem(14)})}>
            arrow_back</Icon>
          <MDTypography
            variant='button'
            fontWeight='medium'
            sx={({palette: {icons}}) => ({color: icons.arrow_icon})}
          >
            Back to List
          </MDTypography>
        </MDBox>
        <Grid container spacing={pxToRem(28)}>
          {/*Product Main Info and Images Section*/}
          <Grid item xs={12} xl={6} height='100%'>
            <Grid container>
              <Grid item xs={10}>
                <MDTypography variant='h2' mt={1} mb={2}>{projectPackage?.name}</MDTypography>
              </Grid>
              <Grid item xs={2} display={'flex'}>
              </Grid>
            </Grid>
            <Grid item xs={12} display='flex' flexDirection={{xs: 'column', xl: 'row'}} gap={{xs: 0, lg: pxToRem(32)}}>
              <MDBox sx={PrimaryImageContainer}>
                <MDBox component='img' src={projectPackage?.logo} sx={PrimaryImage}/>
              </MDBox>
            </Grid>
            <MDTypography variant={'h5'} width={'100%'} pt={2}>Included Items</MDTypography>
            {categories.map(category => renderPackageCategoryProduct(category))}
          </Grid>

          {/*Product Main Info and Images Section*/}

          {/*Product Information Section*/}
          <Grid item xs={12} xl={6}>
            {/*Product Information Summary*/}
            <MDBox display='flex' justifyContent={{xs: 'center', xl: 'flex-start'}}>
              <MDBox sx={{lineBreak: 'auto'}}>
                <MDTypography variant='h2' mt={pxToRem(5)}>
                  {loginStore.isSupplyManager?
                    money_fmt(projectPackage?.SM_price):
                    money_fmt(projectPackage?.price)
                  }
                  <MDTypography variant='button'> /package</MDTypography>
                </MDTypography>
                {/*<MDTypography variant='button'>Model {Product?.model}</MDTypography>*/}
                {/*<MDBox>*/}
                {/*  <MDTypography variant='button'>{Product?.summary}</MDTypography>*/}
                {/*</MDBox>*/}
              </MDBox>
            </MDBox>
            {/*Product Information Summary*/}

            {/*Product General Information Paper*/}
            <MDBox sx={GeneralInfoContainer}>
              <MDTypography variant='h2'>Order</MDTypography>
              <MDBox mt={pxToRem(10)} display='flex' flexDirection='column' gap={pxToRem(15)}>
                <MDBox sx={GeneralInfoItemHeader}>
                  <MDTypography variant='button' fontWeight='regular'>Item</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Unit Cost</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Quantity</MDTypography>
                  <MDTypography variant='button' fontWeight='regular'>Subtotal</MDTypography>
                </MDBox>
                {categories.length > 0 && categories.map((category) => renderOrderCategoryDetails(category))}
                <MDBox sx={Divider}/>
              </MDBox>
              <MDBox mt={pxToRem(14)} display='flex' alignItems='center' justifyContent='space-between'>
                <MDTypography variant='button' fontWeight='bold'>Quantity:</MDTypography>
                <MDInput
                  sx={InputQuantity}
                  onChange={handleProductOrderQuantityChange}
                  value={quantity}
                  inputProps={{min: 1}}
                  type='number'
                />
              </MDBox>
              <MDBox mt={pxToRem(14)} display='flex' alignItems='center' justifyContent='space-between'>
                <MDTypography variant='button' fontWeight='bold'>Grand Total *</MDTypography>
                  <MDTypography variant='h2'>{
                    loginStore.isSupplyManager?
                      money_fmt(projectPackage?.SM_price * quantity)
                    :
                      money_fmt(projectPackage?.price * quantity)}
                  </MDTypography>
              </MDBox>
              {(!loginStore.isSupplyManager || order_or_quote_id)  &&
                <>
                  {!loginStore.isViewer && <MDButton color='secondary' sx={{width: '100%', mt: pxToRem(24)}} onClick={() => setOpenCartModal(true)}>Add
                    to Quote</MDButton>}
                  <MDBox mt={pxToRem(17)}>
                    <MDTypography variant='caption' fontWeight='regular'>* Tax to be added later by vendor based on
                      location.</MDTypography>
                  </MDBox>
                </>
              }
            </MDBox>

            {/*Product General Information Paper*/}

            {/*Accordion Product Details*/}
            <MDBox mt={pxToRem(24)}>
              <Accordion sx={AccordionBox}>
                <AccordionSummary
                  expandIcon={<img src={ExpandMore} alt='expand_more' style={{marginRight: pxToRem(10)}}/>}
                  sx={{minHeight: 'auto !important'}}
                >
                  <MDTypography variant='h2'>Product Details</MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDTypography
                    variant='button'
                    sx={GeneralInfoText}
                  >Additional details about this bundle. You can also click into each product and view its details
                    separately.</MDTypography>
                </AccordionDetails>
              </Accordion>
            </MDBox>
            {/*Accordion Product Details*/}

            {/*Accordion Cut Sheet*/}
            {/*<MDBox sx={AccordionDivider}/>*/}
            {/*<MDBox mt={pxToRem(18)} display='flex' flexDirection='column' gap={pxToRem(5)}>*/}
            {/*  <MDTypography variant='h2'>Questions?</MDTypography>*/}
            {/*  <MDTypography mt={1} mb={1} variant='button' fontWeight='regular'>Get your question answered by the manufacturer or*/}
            {/*    developer</MDTypography>*/}
            {/*  <Autocomplete*/}
            {/*    fullWidth*/}
            {/*    disablePortal*/}
            {/*    sx={{backgroundColor: '#fff'}}*/}
            {/*    options={manufacturers}*/}
            {/*    value={selectedManufacturer}*/}
            {/*    renderInput={(params) => <TextField {...params} label="Manufacturer"/>}*/}
            {/*    onChange={(e, value) => {*/}
            {/*      setSelectedManufacturer(value)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  <MDInput*/}
            {/*    label='Message'*/}
            {/*    multiline*/}
            {/*    rows={10}*/}
            {/*    sx={{mt: 3, background: 'white'}}*/}
            {/*    value={message}*/}
            {/*    onChange={(e) => setMessage(e.target.value)}*/}
            {/*  />*/}
            {/*</MDBox>*/}
            {/*<MDBox display='flex' justifyContent='flex-end' mb={3}>*/}
            {/*  <MDButton*/}
            {/*    variant='outlined'*/}
            {/*    color='success'*/}
            {/*    disabled={loading || selectedManufacturer === null || message === ''}*/}
            {/*    loading={loading}*/}
            {/*    endIcon={<SendIcon color='primary'/>}*/}
            {/*    sx={{width: pxToRem(111), padding: `${pxToRem(8)} ${pxToRem(23)}`}}*/}
            {/*    onClick={() => sendMessageToManufacturer()}*/}
            {/*  >*/}
            {/*    <MDTypography variant='button' fontWeight='bold' color='tertiary'>Send</MDTypography>*/}
            {/*  </MDButton>*/}
            {/*</MDBox>*/}
            {/*Questions*/}
          </Grid>
          {/*Product Information Section*/}
        </Grid>
        <CartSectionModal
          open={openCartModal}
          packageObj={projectPackage}
          quantity={quantity}
          order={order_or_quote_id}
          handleClose={() => setOpenCartModal(false)}
        />
      </MDBox>
    </DashboardProjectManagerLayout>
  );
}
)
