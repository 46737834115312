// noinspection JSUnresolvedFunction

import {FormikProvider, useFormik} from "formik";
import FormikInput from "../../../components/FormikInput";
import MDBox from "../../../components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import * as Yup from "yup";
import {memo, useEffect, useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import {useDebouncedValidate} from "../../../shared/formik_debounce_validate";
import AddBaseModal from "../../../components/add-base-modal/AddBaseModal";
import {
  baseValidationRules,
  projectManagerValidationRules,
  supplyManagerValidationRules
} from "./utils";
import {PERMISSION_TYPES, ROLES} from "../../../services/constants";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import {
  BooleanBox,
  BooleanFieldsContainer,
  BooleanTitle,
  CheckboxItem,
  CheckboxValue
} from "../manage-products/manage_product/styles";
import {Checkbox} from "@mui/material";


export const UserModalForm = memo(({open, item, onClose, onSave, setLoading, loading, userRole = null, modalLayout = true}) => {
  const api = useApi()
  const role = item === null ? userRole : item?.group
  let validationSchema = {}
  switch (role) {
    case ROLES.ADMIN.name:
      validationSchema = baseValidationRules
      break
    case ROLES.PROJECT_MANAGER.name:
      validationSchema = projectManagerValidationRules
      break
    case ROLES.SUPPLY_MANAGER.name:
      validationSchema = supplyManagerValidationRules
      break
    default:
      break
  }
  const [companies, setCompanies] = useState([])
  const [regions, setRegions] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [init, setInit] = useState(false)

  const formik = useFormik({
    initialValues: {
      id: item?.id ? item.id : '',
      name: item?.name ? item.name : '',
      title: item?.title ? item.title : '',
      phone_number: item?.phone_number ? item.phone_number : '',
      email: item?.email ? item.email : '',
      coverage: item?.coverage ? item.coverage : '',
      manufacturer: item?.manufacturer ?  {id: item.manufacturer, name: item.manufacturer_name} : null,
      company: item?.company ?  {id: item.company, name: item.company_name}: null,
      region: item?.region ?  {id: item.region, name: item.region_name} : null,
      permission_type: item?.permission_type ? {id: item.permission_type, name: item.permission_type} : null,
      is_alliance_employee: item?.is_alliance_employee || false
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => save(values),
  })

  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  useEffect(() => {
    getCompanies({search: ''})
    getRegions({search: ''})
    getManufacturers({search: ''})
  }, [])

  const save = (values) => {


    const region = values.region ? values.region.id : null
    const company = values.company ? values.company.id : null
    const manufacturer = values.manufacturer ? values.manufacturer.id : null
    const permission_type = values.permission_type ? values.permission_type.value : null
    const data = {...values, region, company, manufacturer, permission_type}
    setLoading(true)
    if (item?.id) {
      editUser(data)
    } else {
      createUser(data)
    }
  }

  const getCompanies = ({search}) => {
    api.getCompanies({search}).then((result) => {
      if (result.kind === 'ok') {
        setCompanies(result.data.results)
      }
    })
  }
  const getRegions = ({search}) => {
    api.getRegions({search, company_id: selectedCompany}).then((result) => {
      if (result.kind === 'ok') {
        setRegions(result.data.results)
      }
    })
  }

  const getManufacturers = ({search}) => {
    api.getManufacturers({search}).then((result) => {
      if (result.kind === 'ok') {
        setManufacturers(result.data.results)
      }
    })
  }

  const editUser = (data) => {
    api.editUser(data).then((result) => {
      if (result.kind === 'ok') {
        showMessage('User updated successfully', 'success')
        onSave()
      } else if (result.kind === 'bad-data') {
        formik.setErrors(result.errors)
        showMessage('Validation errors found')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const createUser = (data) => {
    const newData = {...data, role}
    api.createUser(newData).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Admin user added successfully', 'success')
        onSave()
      } else if (result.kind === 'bad-data') {
        formik.setErrors(result.errors)
        showMessage('Validation errors found')
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getRegions({search: ''})
    if (init) {
      formik.setFieldValue('region', null)
    }
  }, [selectedCompany])

  useEffect(() => {
    setInit(true)
  }, [])

  const renderForm = () => {
    return (
      <FormikProvider value={formik}>
        {/*<MDTypography variant={"p"} component={"p"} color={"error"} mt={2}>{JSON.stringify(formik.errors)}</MDTypography>*/}
        <FormikInput name="name" label={`${role} Name`}/>
        <FormikInput name="title" label="Title"/>
        <FormikInput name="phone_number" label="Phone number" type="phone_input"/>
        <FormikInput name="email" label="Email Address" type="email"/>
        {role === ROLES.SUPPLY_MANAGER.name &&
          (
        <FormikInput name="coverage" label={'Coverage Area'}/>  )}
        {role === ROLES.SUPPLY_MANAGER.name &&
          (
            <FormikInput
              type={"autocomplete"}
              value={formik.values.manufacturer}
              fieldName={"manufacturer"}
              label={"Manufacturer"}
              options={manufacturers}
              accessKey={"name"}
              onInputChange={(value) => {
                getManufacturers({search: value})
              }}
              onChange={(value) => {
                formik.setFieldValue('manufacturer', value)
              }}/>
          )}
        {role === ROLES.PROJECT_MANAGER.name &&
          (
            <FormikInput
              type={"autocomplete"}
              value={formik.values.company}
              fieldName={"company"}
              label={"Company"}
              options={companies}
              accessKey={"name"}
              onInputChange={(value) => {
                getCompanies({search: value})
              }}
              onChange={(value) => {
                formik.setFieldValue('company', value)
                setSelectedCompany(value?.id ? value?.id : null)
              }}/>
          )}
        {role === ROLES.PROJECT_MANAGER.name &&
          (
            <FormikInput
              type={"autocomplete"}
              value={formik.values.region}
              fieldName={"region"}
              label={"Region"}
              options={regions}
              accessKey={"name"}
              onInputChange={(value) => {
                getRegions({search: value})
              }}
              onChange={(value) => {
                formik.setFieldValue('region', value)
              }}
            />
          )}
        {(role === ROLES.SUPPLY_MANAGER.name || role === ROLES.PROJECT_MANAGER.name) &&
          (
            <FormikInput
              name="permission_type"
              label="Permission"
              labelFieldName={'name'}
              options={PERMISSION_TYPES}
              type="select"
              setFieldValue={formik.setFieldValue}
              errors={formik.errors}
              initialValue={formik.values.permission_type}
            />
          )}
        {(role === ROLES.SUPPLY_MANAGER.name || role === ROLES.PROJECT_MANAGER.name) &&
          <MDBox sx={BooleanFieldsContainer}>
            <MDTypography sx={BooleanTitle}>Is Alliance employee?</MDTypography>
            <MDBox sx={BooleanBox}>
              <Checkbox sx={CheckboxItem}
                        checked={formik.values.is_alliance_employee}
                        onChange={(e) => {
                          formik.setFieldValue('is_alliance_employee', true)
                        }}
              />
              <MDTypography sx={CheckboxValue}>Yes</MDTypography>
            </MDBox>
            <MDBox sx={BooleanBox}>
              <Checkbox sx={CheckboxItem}
                        checked={!formik.values.is_alliance_employee}
                        onChange={(e) => formik.setFieldValue('is_alliance_employee', false)}
              />
              <MDTypography sx={CheckboxValue}>No</MDTypography>
            </MDBox>
          </MDBox>
        }
      </FormikProvider>
    )
  }


  return (
    <>
      {modalLayout && <AddBaseModal
        open={open}
        handleClose={() => {
          formik.resetForm()
          onClose()
        }}
        title={item?.id ? "Edit User" : "Add User"}
        buttonText={"Save"}
        onSubmit={formik.handleSubmit}
        loading={loading}
      >
        <MDBox
          display='flex'
          flexDirection='column'
          gap={pxToRem(22)}
          pt={pxToRem(32)}
          px={pxToRem(45)}
        >
          {renderForm()}
        </MDBox>
      </AddBaseModal>}
      {!modalLayout &&         <MDBox
        display='flex'
        flexDirection='column'
        gap={pxToRem(22)}
        pt={pxToRem(32)}
        px={pxToRem(45)}
      >
        {renderForm()}
        <MDBox mt={10} mb={1} display={'flex'} flex={1} justifyContent={'space-between'}>
          <MDButton
            variant="outlined"
            color="secondary"
            loading={loading}
            disabled={loading}
            onClick={() => onClose()}
            type='button'
            sx={{width: pxToRem(150), height: pxToRem(45)}}
          >
            Cancel
          </MDButton>
          <MDButton
            variant="contained"
            color="secondary"
            loading={loading}
            disabled={loading}
            onClick={() => formik.submitForm()}
            sx={{width: pxToRem(150), height: pxToRem(45)}}
          >
            Add User
          </MDButton>
        </MDBox>
      </MDBox>}
    </>

  )
})

