import React, {useEffect, useState} from 'react';
import {Grid, Modal, Popover} from "@mui/material";
import MDButton from "../MDButton";
import MDTypography from "../MDTypography";
import MDBox from "../MDBox";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {showMessage, useApi} from "../../services/helpers";
import {useParams} from "react-router-dom";
import MDInput from "../MDInput";
import ConfirmDialogModal from "../ConfirmDialogModal";
import {CartConfirmationModal} from "../CartConfirmationModal";
import Icon from "@mui/material/Icon";
import {ROLES} from "../../services/constants";
import {PopoverDeleteText} from "../../pages/admin/manage-projects/styles";
import {PopoverActions, PopoverItem} from "../PopoverActions";
import {useStores} from "../../models";


const CartSectionModal = (
  {
    open,
    handleClose,
    packageObj = null,
    product = null,
    quantity = null,
    installation = false,
    shipping = false,
      order = null,
  }) => {
  const api = useApi()
  const {id: idProject} = useParams();
  const rootStore = useStores();
  const {loginStore, projectStore} = rootStore;
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [addSection, setAddSection] = useState(false);
  const [cartSections, setCartSections] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [sectionError, setSectionError] = useState('');
  const [selectedSection, setSelectedSection] = useState(null);
  const [addedInfo, setAddedInfo] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const getProjectCartSections = () => {
    setLoading(true)
    api.getProjectCartSections(idProject).then((result) => {
      if (result.kind === "ok") {
        setCartSections(result.data)
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const createProjectCartSection = () => {
    setLoading(true)
    api.createProjectCartSection(idProject, {name: sectionName}).then((result) => {
      if (result.kind === "ok") {
        setSectionName('')
        setSectionError('')
        showMessage('Section created successfully', 'success');
        getProjectCartSections()
      } else if (result.kind === "bad-data") {
        setSectionError(result?.errors?.name[0])
        showMessage('Validation errors found')
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const addProductToCartSection = () => {
    api.addItemToCartOrQuote(idProject, {
        cart_section: selectedSection.id,
        product: product?.id,
        package: packageObj?.id,
        order: order,
        quantity: quantity,
        installation: installation,
    }).then((result) => {
        if (result.kind === "ok") {
            showMessage('Product added successfully', 'success');
            setAddedInfo({...result.response, qty: quantity, installation: installation, shipping})
            setOpenConfirmationModal(true)
            setConfirmModal(false)
            handleClose()
        }else{
            throw new Error()
        }
    }).catch(err => showMessage('Error adding product'))
  }
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setAddedInfo(null)
  }

  const onDelete = () => {
    setLoading(true)
    api.deleteProjectCartSection(idProject, selectedSection?.id).then((result) => {
      if (result.kind === 'ok') {
        showMessage('Section deleted successfully', 'success')
        getProjectCartSections()
        onClose()
      } else if (result.kind === 'bad-data') {
        showMessage(result.errors[0])
      } else {
        showMessage()
      }
    })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const onClose = () => {
    setConfirmModal(false)
    setOpenConfirmModal(false)
    setSelectedSection(null)
    setOpenPopover(false)
  }

  useEffect(() => {
    if (!addSection) {
      setSectionName('')
      setSectionError('')
    }
  }, [addSection])

  useEffect(() => {
    if (open) getProjectCartSections()
  }, [open])

  const renderItem = (item, index) => {
    return (
      <MDBox sx={styles.itemList} key={index}>
        <MDTypography sx={styles.itemTitle}>{item.name}</MDTypography>
        <MDButton
          disabled={loading}
          size={'small'}
          iconOnly
          variant='contained'
          color='primary'
          sx={styles.itemButton}
          onClick={() => {
            setSelectedSection(item)
            setConfirmModal(true)}
        }
        >
          +
        </MDButton>
        {!loginStore.isSupplyManager && !item.has_products &&
          <Icon
            fontSize='medium' sx={{cursor: 'pointer'}}
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
              setOpenPopover(true)
              setSelectedSection(item)
            }}
          >
            more_vert
          </Icon>
        }
        <ConfirmDialogModal
          title={'Do you want to delete this section?'}
          description={`${selectedSection?.name}`}
          cancelText={'Cancel'}
          confirmText={'Confirm'}
          open={openConfirmModal}
          handleClose={() => onClose()}
          handleConfirm={() => onDelete()}
        />
        <PopoverActions open={openPopover} onClose={onClose} anchorEl={anchorEl}>
          <PopoverItem label={"Delete"} onClick={() => setOpenConfirmModal(true)} sx={PopoverDeleteText}/>
        </PopoverActions>
      </MDBox>
      )
  }

  return (
    <>
      <CartConfirmationModal
        addedInfo={addedInfo}
        openConfirmationModal={openConfirmationModal}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
      />
      <Modal
        open={open}
        onClose={handleClose}
      >
        <MDBox sx={styles.modal}>
          <ConfirmDialogModal
            title={'Do you want to add this item?'}
            description={'You will add this item to an existing quote section or create a new one. Depending on the manufacturer, you may be able to add this item to a quote section that already exists.'}
            cancelText={'Cancel'}
            confirmText={'Confirm'}
            open={confirmModal}
            handleClose={() => onClose()}
            handleConfirm={() => addProductToCartSection()}
          />
          <Grid container mt={3}>
            <Grid item xs={6}>
              <MDTypography sx={styles.titleLeft}>Add to Quote Section:</MDTypography>
            </Grid>
            <Grid item xs={6} mb={3}>
              {!addSection &&<MDTypography sx={styles.titleRight} onClick={() => setAddSection(!addSection)}>Create New Section</MDTypography>}
              {addSection &&<MDTypography sx={styles.titleRight} onClick={() => setAddSection(!addSection)}>View List of Sections</MDTypography>}
            </Grid>
            {!addSection &&<Grid item xs={12} sx={{height: 350, overflowY: 'scroll'}}>
              {cartSections.length > 0 && cartSections.map((item, index) => renderItem(item, index))}
              {cartSections.length === 0 && <MDTypography sx={styles.itemEmpty}>No Cart Section Items Found</MDTypography>}
            </Grid>}
            {addSection && <Grid item xs={12} display={'flex'} flex={1}>
              <MDInput
                value={sectionName}
                onChange={(e) => setSectionName(e.target.value)}
                sx={{margin: pxToRem(20)}}
                type={'text'}
                label={'New Section Name'}
                fullWidth
                helperText={sectionError}
                error={sectionError !== ''}
                disabled={loading}
              />
            </Grid>}
          </Grid>
          <MDBox display='flex' flexDirection='column' mx={pxToRem(15)} mt={'auto'} mb={5}>
            {addSection && <MDButton
              loading={loading}
              disabled={loading || sectionName === ''}
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => createProjectCartSection()}
              sx={{marginBottom: 2}}
            >
              Create Section
            </MDButton> }
            <MDButton disabled={loading} fullWidth variant='outlined' color='secondary' onClick={handleClose}>
              Cancel
            </MDButton>
          </MDBox>
        </MDBox>

      </Modal>
    </>
  )
};

export default CartSectionModal;

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 410,
    borderRadius: "10px",
    backgroundColor: '#fff',
    border: 'none',
    boxShadow: 24,
    height: 520,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  titleLeft: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 600,
    textAlign: 'center',

  },
  titleRight: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 600,
    textAlign: 'center',
    color: '#0B7340',
    cursor: 'pointer'
  },
  itemList: {
    border: '1px solid #DBDBDB',
    borderRadius: pxToRem(8),
    marginLeft: pxToRem(15),
    marginRight: pxToRem(15),
    marginTop: pxToRem(7.5),
    marginBottom: pxToRem(7.5),
    display: 'flex',
    height: pxToRem(50),
    alignItems: 'center',
  },
  itemTitle: {
    width: pxToRem(300),
    fontWeight: '600',
    fontSize: pxToRem(17),
    marginLeft: pxToRem(15),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemButton: {
    width: pxToRem(30),
    height: pxToRem(30),
    borderRadius: pxToRem(15),
    fontSize: pxToRem(20),
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    fontWeight: '400'
  },
  itemEmpty: {
    fontWeight: '600',
    fontSize: pxToRem(17),
    alignItems: 'center',
    textAlign: 'center',
    marginTop: pxToRem(100),
  },
}
